@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

body {
  font-family: "Manrope", sans-serif;
  background-color: #f7f8fa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-time-picker__wrapper {
  border: none !important;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: #04457b;
  width: 1em;
  height: 1em;
  border: 0.15em solid #04457b;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}
input[type="checkbox"]::before {
  content: "";
  width: 1em;
  height: 1em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #04457b;
}
input[type="checkbox"]:checked::before {
  transform: scale(1);
}
.react-tel-input .form-control {
  width: 100% !important;
  padding-top: 15px !important;
  padding-right: 10px !important;
  padding-bottom: 15px !important;
  padding-left: 55px !important;
  border-radius: 8px !important;
  background-color: #f1f5f9 !important;
  border: none !important;
  font-size: 14px !important;
}

.react-tel-input .form-control:focus {
  border-color: transparent !important;
  box-shadow: 0 0 0 1px transparent !important;
}

.react-tel-input .special-label {
  display: none !important;
}
/* .react-toggle-track {
  width: 35px;
  height: 18px;
  background-color: #f1f5f9 !important;
}

.recipientPays .react-toggle-track {
  width: 45px;
  height: 18px;
  background-color: #12233577 !important;
}

.recipientPays .react-toggle-thumb {
  top: -2px;
  width: 23px !important;
  height: 23px !important;
  background-color: #94a3b8 !important;
  border: none !important;
}

.react-toggle-thumb {
  top: 2px;
  left: 2px;
  width: 13px;
  height: 13px;
  background-color: #94a3b8 !important;
  border: none !important;
}

.react-toggle--checked .react-toggle-thumb {
  background-color: #04457b !important;
} */

::-webkit-scrollbar-thumb {
  background: #04457b;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.react-toggle-track {
  width: 35px;
  height: 18px;
  background-color: #1d293f;
}

.react-toggle-thumb {
  top: 2px;
  left: 2px;
  width: 13px;
  height: 13px;
}

.react-toggle--checked .react-toggle-thumb {
  left: 20px;
  border-color: #19ab27;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
/* ::-webkit-scrollbar-track {
  background: transparent; 
} */

/* Handle */
::-webkit-scrollbar-thumb {
  background: #94A3B8;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #94A3B8;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.searchWrapper {
  display: flex;
  gap: 0 4px;
  align-items: center;
  border: none !important;
  /* min-height: 50px !important; */
  height: 100% !important;
  overflow-x: auto;
}

.searchWrapper .chip {
  /* background: #04457b !important; */
  background: white !important;
  color: black;
  margin: 0;
  /* padding: 4px 20px 4px 10px; */
}

.searchWrapper input {
  width: 100%;
}

.icon_down_dir::before {
  display: none;
}

.highlightOption {
  background-color: white !important;
  color: black !important;
}

.rdrDefinedRangesWrapper {
  display: none !important;
}

.rdrDateDisplayItemActive {
  border-color: #ffc72b !important;
}

.rdrInRange {
  background-color: #ffc72b !important;
}

.rdrDateRangePickerWrapper {
  display: inline-flex !important;
  justify-content: center !important;
}

.multi-select {
  font-size: 12px !important;
}

.dropdown-heading {
  height: 45px !important;
}
.dropdown-container {
  border: none !important;
  background: #f1f5f9 !important;
}
.dropdown-heading-value span {
  font-size: 12px !important;
}
